<template>
  <Family />
</template>

<script>
import Family from "../components/family/Family";
export default {
  components: {
    Family
  }
};
</script>

<style></style>
